<template>
  <b-container v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <h2 style="margin-top: 4rem; margin-bottom: 1.3rem;">Neigungsstufen</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <img class="image" src="@/assets/pictures/step4.png" alt="tutorial" style="width: 100%; height: 100%;"/>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <p style="margin-top: 1.3rem;">Du kannst über den Drehgriff links die Neigungsunterstützung an und aus machen.<br>Wenn man zwei Pfeile sieht ist die Unterstützung aus, so kannst du nur sehr schlecht um Kurven fahren. Wenn insgesamt vier Pfeile zu sehen sind, ist die Unterstützung an.</p>
        <p style="margin-bottom: 4rem;">Der Drehgriff hat 7 Rastpositionen, von denen jedoch nur drei verwendet werden.</p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row class="fixed-bottom pageIndicatorPos">
      <b-col cols="2"/>
      <b-col cols="8">
        <PageIndicator route="bikeTutorial" :max="6" :pos="4"/>
      </b-col>
      <b-col cols="2"/>
    </b-row>
      <b-row>
      <NextButton text="Weiter" next @click="$router.push('/biketutorial/5')" />
    </b-row>
  </b-container>
</template>

<script>
import PageIndicator from '../components/PageIndicator';
import NextButton from '../components/NextButton';
  export default {
    name: 'BikeTutorial4',
    components: {
      PageIndicator,
      NextButton,
    },
    methods: {
      swipeLeft() {
        this.$router.push('/bikeTutorial/5')
      },
      swipeRight() {
       this.$router.push('/bikeTutorial/3')
      }
    }
  }
</script>

<style scoped>

.image {
  border-radius: 0.5rem;
}

</style>